import React, { useEffect, useState } from 'react';
import './cuentas.css';
import { useNavigate } from 'react-router-dom';
import { fetchEvents, checkUserRole } from '../../hooks/customhooks';
import { useTranslation } from 'react-i18next';
import TablaResumen from './TablaResumen';
import TablaDesglosada from './tablaDesglosada';
import axios from 'axios';  // Importar axios

const Cuentas = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [resumenData, setResumenData] = useState(null);
  const [entradasData, setEntradasData] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const { t, i18n } = useTranslation('cuentas');

  const handleEventChange = (event) => {
    const selectedEventId = Number(event.target.value);
    setSelectedEvent(selectedEventId);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedEvent) {
      try {
        // Llamada a la API utilizando axios
        const response = await axios.post('https://api.elplanmadrid.org/cuentas', { token, "evento": selectedEvent });
        setResumenData(response.data);  // Guardar los datos del resumen en el estado

        const entradasResponse = await axios.post('https://api.elplanmadrid.org/entradas_otorgadas', { token, "evento": selectedEvent });


        setEntradasData(entradasResponse.data);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    }
  };


  useEffect(() => {
    if (!token) {
      navigate('/error');
      return;
    }

    const verifyRoleAndFetchEvents = async () => {
      const hasSufficientRole = await checkUserRole(3, token);

      if (!hasSufficientRole) {
        navigate('/error');
        return;
      }

      const eventsData = await fetchEvents(token, true);
      setEvents(eventsData);
    };

    verifyRoleAndFetchEvents();
  }, [token, navigate]); // Agregamos token y navigate como dependencias

  const formatDate = (dateString, eventId) => {
    if (eventId === -1) {
      return ''; // No devolver la fecha si el ID del evento es -1
    }
    const date = new Date(dateString);
    const options = {
      timeZone: 'Europe/Madrid',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    };
    return date.toLocaleString(i18n.language === 'es' ? 'es-ES' : 'en-US', options);
  };


  return (
    <div className="cuentas">
      <div className='container mt-5'>
        <h2 className="mt-5 mb-4">{t('Titulo')}</h2>
        <form onSubmit={handleSubmit}>
          <select
            className="form-control mt-5 mb-4"
            value={selectedEvent}
            onChange={handleEventChange}
          >
            <option value="">{t('SeleccionarEvento')}</option>
            {events.map(event => (
              <option key={event.id} value={event.id}>
                {event.nombre} {event.id !== -1 && `(${formatDate(event.fecha, event.id)})`}
              </option>
            ))}
          </select>
          <button
            type="submit"
            className="btn btn-primary mb-3"
            disabled={!selectedEvent} // El botón está deshabilitado si no hay un evento seleccionado
          >
            {t('Confirmar')}
          </button>
        </form>

        {/* Mostrar la tabla resumen si hay datos */}
        {resumenData && <TablaResumen datos={resumenData} />}
        {/* Mostrar la tabla desglosada si hay datos de entradas */}
        {entradasData && Array.isArray(entradasData.entradas) && (
          <>
            <TablaDesglosada entradas={entradasData.entradas} eventID={selectedEvent} />
          </>
        )}



      </div>
    </div>
  );
};

export default Cuentas;
