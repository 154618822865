import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './privacy.css';

const Privacy = () => {
    const { t } = useTranslation('privacy');

    return (
        <Container className="privacy-container mt-5">
            <main className='main-cont'>
                <h1 className="privacy-title">{t('Titulo')}</h1>
                <p className="body-text-privacy">{t('Introduccion')}</p>
                <h2 className="secondary-title">{t('Seccion1Titulo')}</h2>
                <p className="body-text-privacy">{t('Seccion1Contenido')}</p>
                <ul className='privacy-list'>
                    <li><u>{t('Seccion1Info1Titulo')}</u>: {t('Seccion1Info1')}</li>
                    <li><u>{t('Seccion1Info2Titulo')}</u>: {t('Seccion1Info2')}</li>
                    <li><u>{t('Seccion1Info3Titulo')}</u>: {t('Seccion1Info3')}</li>
                    <li><u>{t('Seccion1Info4Titulo')}</u>: {t('Seccion1Info4')}</li>
                </ul>
                <h2 className="secondary-title">{t('Seccion2Titulo')}</h2>
                <p className="body-text-privacy">{t('Seccion2Contenido')}</p>
                <ul class="privacy-list">
                    <li><u>{t('Seccion2Info1Titulo')}</u>: {t('Seccion2Info1')}</li>
                    <li><u>{t('Seccion2Info2Titulo')}</u>: {t('Seccion2Info2')}</li>
                    <li><u>{t('Seccion2Info3Titulo')}</u>: {t('Seccion2Info3')}</li>
                </ul>
                <h2 className="secondary-title">{t('Seccion3Titulo')}</h2>
                <p className="body-text-privacy">{t('Seccion3Contenido')}</p>
                <ul class="privacy-list">
                    <li class="bullet-privacy"><u>{t('Seccion3Info1Titulo')}</u>: {t('Seccion3Info1')}</li>
                    <li class="bullet-privacy"><u>{t('Seccion3Info2Titulo')}</u>: {t('Seccion3Info2')}</li>
                    <li class="bullet-privacy"><u>{t('Seccion3Info3Titulo')}</u>: {t('Seccion3Info3')}</li>
                </ul>
                <h2 className="secondary-title">{t('Seccion4Titulo')}</h2>
                <p className="body-text-privacy">{t('Seccion4Contenido')}</p>
                <ul class="privacy-list">
                    <li class="bullet-privacy"><u>{t('Seccion4Info1Titulo')}</u>: {t('Seccion4Info1')}</li>
                    <li class="bullet-privacy"><u>{t('Seccion4Info2Titulo')}</u>: {t('Seccion4Info2')}</li>
                </ul>
                <h2 className="secondary-title">{t('Seccion5Titulo')}</h2>
                <p className="body-text-privacy">{t('Seccion5Contenido')}</p>
                <ul class="privacy-list">
                    <li class="bullet-privacy"><u>{t('Seccion5Info1Titulo')}</u>: {t('Seccion5Info1')}</li>
                    <li class="bullet-privacy"><u>{t('Seccion5Info2Titulo')}</u>: {t('Seccion5Info2')}</li>
                    <li class="bullet-privacy"><u>{t('Seccion5Info3Titulo')}</u>: {t('Seccion5Info3')}</li>
                    <li class="bullet-privacy"><u>{t('Seccion5Info4Titulo')}</u>: {t('Seccion5Info4')}</li>
                    <li class="bullet-privacy"><u>{t('Seccion5Info5Titulo')}</u>: {t('Seccion5Info5')}</li>
                </ul>
                <p className="body-text-privacy">{t('Seccion5Contacto')}</p>
                <h2 className="secondary-title">{t('Seccion6Titulo')}</h2>
                <p className="body-text-privacy">{t('Seccion6Contenido')}</p>
                <h2 className="secondary-title">{t('Seccion7Titulo')}</h2>
                <p className="body-text-privacy">{t('Seccion7Contenido')}</p>
                <h2 className="secondary-title">{t('Seccion8Titulo')}</h2>
                <p className="body-text-privacy">{t('Seccion8Contenido')}</p>
            </main>
        </Container>
    );
};

export default Privacy;
