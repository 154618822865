import Logs from './pages/Logs/Logs';
import Cookies from './pages/Cookies/Cookies';
import Legal from './pages/legal/legal';
import Recuperacion from './pages/recuperacion/Recuperacion';
import Cuentas from './pages/cuentas/cuentas';
import Entradas from './pages/entradas/entradas';
import Privacy from './pages/privacy/privacy';
import Tos from './pages/tos/tos';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home/Home';
//import Shop from './pages/Shop/Shop';
import Error from './pages/Error/Error';
import BarraNavegacion from './Navbar/Navbar';
import Registro from './pages/Registro/Registro';
import Perfil from './pages/Perfil/Perfil';
import Login from './pages/Login/Login'
import Logout from './pages/Logout/Logout';
import EditarInformacion from './pages/EditarInformacion/Editar';
import Galeria from './pages/Galeria/Galeria';
import Lector from './pages/LectorQR/Lector';
import Masinfo from './pages/MasInfo/MasInfo';
import ScrollToTop from './ScrollToTop';


function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className='App'>
        <BarraNavegacion />
        <Routes><Route path='/' exact element={<Home />} />
          {/*<Route path='/tienda' exact element={<Shop />} />
          <Route path='/shop' exact element={<Navigate to="/tienda" />} />*/}
          <Route path='/error' exact element={<Error />} />
          <Route path='/registro' exact element={<Registro />} />
          <Route path='/register' exact element={<Navigate to="/registro" />} />
          <Route path='/perfil' exact element={<Perfil />} />
          <Route path='/profile' exact element={<Navigate to="/perfil" />} />
          <Route path='/login' exact element={<Login />} />
          <Route path='/logout' exact element={<Logout />} />
          <Route path='/editarinformacion' exact element={<EditarInformacion />} />
          <Route path='/editinformation' exact element={<Navigate to="/editarinformacion" />} />
          <Route path='/galeria' exact element={<Galeria />} />
          <Route path='/gallery' exact element={<Navigate to="/galeria" />} />
          <Route path='/Lector' exact element={<Lector />} />
          <Route path='/masinfo' exact element={<Masinfo />} />
          <Route path='/moreinfo' exact element={<Navigate to="/masinfo" />} />
          <Route path='/tos' exact element={<Tos />} />
          <Route path='/privacy' exact element={<Privacy />} />
          <Route path='/entradas' exact element={<Entradas />} />
          <Route path='/cuentas' exact element={<Cuentas />} />
          <Route path='/recuperacion' exact element={<Recuperacion />} />
          <Route path='/legal' exact element={<Legal />} />
          <Route path='/cookies' exact element={<Cookies />} />
          <Route path="*" element={<Navigate to="/error" />} />
<Route path='/logs' exact element={<Logs />} />
</Routes>
      </div>
    </Router>
  );
}

export default App;
