import React, { useState, useEffect, useCallback } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [enviado, setEnviado] = useState(false);
    const [error, setError] = useState(null);
    const [num1, setNum1] = useState(0);
    const [num2, setNum2] = useState(0);
    const [captchaAnswer, setCaptchaAnswer] = useState('');  
    const [captchaQuestion, setCaptchaQuestion] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation('login');
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (token) {
            navigate("/perfil");
        }
    }, [token, navigate]);

    const numberToWord = (number) => {
        const words = ['cero', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
        return t(words[number]);
    };

    // Envolver la función en useCallback para evitar la advertencia de ESLint
    const generateNewCaptcha = useCallback(() => {
        const randomNum1 = Math.floor(Math.random() * 10);
        const randomNum2 = Math.floor(Math.random() * 10);
        setNum1(randomNum1);
        setNum2(randomNum2);
        setCaptchaQuestion(`${numberToWord(randomNum1)} + ${numberToWord(randomNum2)}`);
    }, []);

    useEffect(() => {
        generateNewCaptcha(); 
    }, [generateNewCaptcha]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setEnviado(true);
    
        const formlogin = {
            email,
            password,
            num1,              
            num2,
            captchaAnswer     
        };
    
        try {
            const res = await axios.post(`https://api.elplanmadrid.org/login`, formlogin);
            if (res.status === 200) {
                const token = res.data.token;
                localStorage.setItem('token', token);
                window.dispatchEvent(new Event('login'));
                navigate("/perfil");
            }
        } catch (error) {
            console.log(error)
            console.error(t('login_invalido'), error);
            if (error.response && (error.response.status === 401 || error.response.status === 404)) {
                setError(t('UsuarioContraseñaNoValidos'));
                setCaptchaAnswer('');
                setPassword('');
                generateNewCaptcha();  
            } else if (error.response && (error.response.status === 403 && error.response.data.error === 'Captcha incorrecto')) {
                setError(t('CaptchaIncorrecto'));
                setCaptchaAnswer('');
                setPassword('');
                generateNewCaptcha();  
            } else {
                setError(t('ErrorInterno'));
            }
        }
    };

    const handleRegistro = () => {
        navigate("/registro");
    };

    const handleReset = () => {
        navigate("/recuperacion");
    };

    return (
        <Form onSubmit={handleSubmit}>
            <div className='pagina-login'>
                <div className="whole-container">
                    <div className='login-card'>
                        <h2 className="login"> Log in</h2>
                        <div className="input-cont">
                            <Form.Group className="email-container" controlId="email">
                                <Form.Label className="fw-bold">{t('Email')}:</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder={t('Email')}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="pass-container" controlId="password">
                                <Form.Label className="fw-bold">{t('Contraseña')}:</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder={t('Contraseña')}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            {/* Captcha */}
                            <Form.Group className="captcha-container" controlId="captcha">
                                <Form.Label className="fw-bold">{t('Captcha')}:</Form.Label>
                                <p>{captchaQuestion}</p> {/* Aquí se muestra la pregunta del captcha */}
                                <Form.Control
                                    type="text"
                                    placeholder={t('Respuesta')}
                                    value={captchaAnswer}
                                    onChange={(e) => setCaptchaAnswer(e.target.value)}
                                    required
                                />
                            </Form.Group>
                        </div>
                        {enviado && error && <div className="danger-cont"> <p className="text-danger">{error}</p> </div>}

                        <Button className="log-in" variant="primary" type="submit">{t('IniciarSesion')}</Button>

                        <div className="recovery-cont">
                            <div className="pass-recover">
                                <p>{t('RecuperarContraseña')}</p>
                                <Button
                                    className="recover-btn"
                                    onClick={handleReset}
                                >
                                    {t('Reiniciala')}
                                </Button>
                            </div>
                            <div className="create-account">
                                <p>
                                    <strong>{t('NoTienesCuentaParte1')}</strong>
                                </p>

                                <Button className="create-btn" onClick={handleRegistro}>{t('NoTienesCuentaParte2')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default Login;
