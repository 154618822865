import React, { useEffect, useState, useRef } from "react";
import './Perfil.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
//import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';

const Perfil = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('perfil');
    const [perfil, setPerfil] = useState(null);
    const [entradas, setEntradas] = useState([]);
    const [imagenPerfil, setImagenPerfil] = useState(null);
    const [error, setError] = useState(null);
    const fileInputRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [deleteError, setDeleteError] = useState('');
    const [password, setPassword] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const token = localStorage.getItem('token');

    useEffect(() => {
        return () => {
            if (imagenPerfil) {
                URL.revokeObjectURL(imagenPerfil)
            }
        };
    }, [imagenPerfil])

    useEffect(() => {
        if (!token) {
            navigate("/login");
            return;
        }

        const fetchData = async () => {
            try {
                const [perfilResponse, entradasResponse] = await Promise.all([
                    axios.post(`https://api.elplanmadrid.org/datos`, { token }),
                    axios.post(`https://api.elplanmadrid.org/qr`, { token })
                ]);

                if (perfilResponse.status === 200) {
                    setPerfil(perfilResponse.data);
                    window.dispatchEvent(new Event('login'));

                    // Fetch image profile after profile data is available
                    setIsLoading(true);  // Iniciar el spinner de carga
                    const imagenResponse = await axios.post(`https://api.elplanmadrid.org/imagen_perfil`, { token }, { responseType: 'blob' });

                    if (imagenResponse.status === 200) {
                        const imageUrl = URL.createObjectURL(imagenResponse.data);
                        setImagenPerfil(imageUrl);  // Actualizar la imagen cargada
                        setIsLoading(false);  // Detener el spinner de carga
                    } else {
                        setImagenPerfil("/images/Mascota.png");
                        setIsLoading(false);  // Detener el spinner de carga
                    }
                } else {
                    localStorage.removeItem('token');
                    navigate("/login");
                }

                if (entradasResponse.status === 200) {
                    setEntradas(entradasResponse.data.entradas);
                }
            } catch (error) {
                console.error('Error al obtener los datos', error);
                localStorage.removeItem('token');
                navigate("/login");
            }
        };

        fetchData();
    }, [token, navigate]);



    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate("/");
        window.dispatchEvent(new Event('logout'));
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            timeZone: 'Europe/Madrid',  // Zona horaria de Madrid
            hour: '2-digit',
            minute: '2-digit',
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        };
        return date.toLocaleString(i18n.language === 'es' ? 'es-ES' : 'en-US', options);
    };

    const formatDate2 = (dateString) => {
        const date = new Date(dateString);
        const options = {
            timeZone: 'Europe/Madrid',  // Aseguramos la zona horaria de Madrid
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        };
        return date.toLocaleString(i18n.language === 'es' ? 'es-ES' : 'en-US', options);
    };


    const handleImageUpload = () => {
        if (selectedImage && token) {
            const formData = new FormData();
            formData.append('imagen', selectedImage);
            formData.append('token', token);

            axios.post(`https://api.elplanmadrid.org/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(async response => {
                    if (response.status === 200) {
                        setError(null);
                        fileInputRef.current.value = '';

                        // Obtener la imagen actualizada después de la subida
                        const imagenResponse = await axios.post(`https://api.elplanmadrid.org/imagen_perfil`, { token }, { responseType: 'blob' });
                        if (imagenResponse.status === 200) {
                            const imageUrl = URL.createObjectURL(imagenResponse.data);
                            setImagenPerfil(imageUrl);  // Actualizar la imagen ya subida
                            setSelectedImage(null);  // Limpiar la imagen seleccionada
                        }
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 440) {
                            setError(t('max_size'));
                        } else {
                            setError(t('ErrorDesconocidoImagen'));
                        }
                    } else {
                        console.error('Error al subir la imagen', error);
                        setError(t('ErrorDesconocidoImagen'));
                    }
                });
        } else {
            setError(t('SeleccioneImagen'));
        }
    };


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.size > 5 * 1024 * 1024) {  // 5 MB
            setError(t('max_size'));
            return;
        }
        setSelectedImage(file);
        setError(null);
    };


    const handleEditar = () => {
        navigate("/editarinformacion");
    };

    const handleBorrar = () => {
        setShowModal(true);
    };

    const handleLector = () => {
        navigate("/lector")
    };

    const handleEntradas = () => {
        navigate("/entradas")
    };

    const handleCuentas = () => {
        navigate("/cuentas")
    };

    const handleLogs = () => {
        navigate("/logs")
    };

    const confirmDelete = () => {
        const token = localStorage.getItem('token');
        axios.post(`https://api.elplanmadrid.org/borrarCuenta`, { token, password })
            .then(response => {
                if (response.status === 200) {
                    localStorage.removeItem('token');
                    navigate("/");
                    window.dispatchEvent(new Event('logout'));
                }
            })
            .catch(error => {
                if (error.response.status === 401) {
                    setDeleteError(t('ContrasenaIncorrecta'));
                }
                else {
                    setDeleteError(t('ErrorInterno'));
                }
            });
    };

    /*const formatPhoneNumber = (phone) => {
        if (phone !== '-') {
            phone = '+' + phone;
            const phoneNumber = parsePhoneNumberFromString(phone);
            if (phoneNumber) {
                let code = '+' + phoneNumber.countryCallingCode;
                return `(${code}) ${phoneNumber.formatNational()}`;
            }
        }
        return phone;
    };*/

    return (
        <div className="body-profile">
            {perfil ? (
                <>
                    <div className="container-info">
                        <div className="perfil-info">
                            <div className="text-start info">
                                <div class="info-txt">
                                    <h1 className="mt-3">
                                        <strong>{t('BienvenidoParte1')}</strong> {perfil.username}!
                                    </h1>
                                    {/*<p><strong>{t('Nombre')}</strong> {perfil.nombre}</p>
                                    <p><strong>{t('Apellidos')}</strong> {perfil.apellidos}</p>*/}
                                    <p><strong>{t('Email')}</strong> {perfil.email}</p>
                                    <p><strong>{t('FechaNacimiento')}</strong> {formatDate2(perfil.nacimiento)}</p>
                                    {/*<p><strong>{t('Telefono')}</strong> {formatPhoneNumber(perfil.telefono)}</p>*/}
                                    <p><strong>{t('Telegram')}</strong> @{perfil.telegram}</p>
                                    <div className="boton-container">
                                        <button className="btn btn-primary boton" onClick={handleEditar}>{t('EditarInformacion')}</button>
                                        <button onClick={handleLogout} className="btn btn-primary logout">{t('Logout')}</button>
                                    </div>
                                    <div class="info-decor">
                                        <img className="pillo-decor" src="images/walkingpillo.png" alt="Walking Pillo"/>
                                    </div>
                                </div>
                                <div class="img-upload">
                                    {isLoading ? (
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) : (
                                        <img src={imagenPerfil || "/images/noimg.png"} alt="Perfil" className="img-fluid perfil-img
                                        " />
                                    )}
                                    <input ref={fileInputRef} type="file" accept="image/png, image/jpeg, image/jpg" onChange={handleImageChange} className="form-control mt-3 mb-3" />
                                    <button onClick={handleImageUpload} className="btn btn-primary mt-2 boton">{t('CambiarImagen')}</button>
                                    <div class="instructions">
                                        {error && <div className="alert alert-danger mt-3">{error}</div>}
                                        <div className="picture-intructions">
                                            <p className="instruction">{t('FormatosPermitidos')}</p>
                                            <p className="instruction">{t('size')}</p>
                                            <p className="instruction">{t('RecomendacionImagen')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="btn-cont-delete">
                                <button className="btn btn-danger" onClick={handleBorrar}>{t('EliminarCuenta')}</button>
                            </div>

                        </div>
                    </div>

                    <div className="container-img">
                        <div class="qr-title">
                            <div class="main-title">
                                <img src="images/chinchin.png" alt="dibujo de copas" />
                                <h2 class="title-ticket">¡Aquí está tu entrada!</h2>
                                <img src="images/chinchin.png" alt="dibujo de copas" />
                            </div>
                            <h4> ¡No la pierdas! </h4>
                        </div>
                        <div className="qr-cont">
                            {entradas.map((entrada, index) => (
                                <div key={index} className="qr-code">
                                    <div className="qr-card">
                                        <div className="qr-img">
                                            <img src={`data:image/png;base64,${entrada.qr}`} className="card-img-top" alt="QR" />
                                        </div>
                                        <div className="card-body">
                                            <p className="ticket-name"><u>{entrada.nombre}</u></p>
                                            <p className="ticket-number"><strong>{t('EntradaNumero')}</strong> {entrada.compra_id}</p>
                                            <p className="event-date">{formatDate(entrada.fecha)}</p>
                                            <p className="event-where">
                                                Ubicación: <a href={entrada.url} target="_blank" rel="noopener noreferrer">{entrada.ubicacion}</a>
                                            </p>
                                            <p className="text-danger ticket">{t('EntradasIntransferibles')}</p>
                                            <p className="event-contact">{t('ContactarAdministracion')}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {perfil.rol >= 2 && (
                        <div className="admin-container mt-3">
                            <div className="admin-blocks">
                                <div className="admin">
                                    <h2>{t('Admin')}</h2>
                                    <button className="btn btn-primary boton" onClick={handleLector}>{t('Lector')}</button>
                                    <button className="btn btn-primary boton" onClick={handleEntradas}>{t('Entradas')}</button>
                                </div>
                                {perfil.rol >= 3 && (
                                    <div className="superadmin">
                                        <h2>{t('Superadmin')}</h2>
                                        <button className="btn btn-primary boton" onClick={handleCuentas}>{t('Cuentas')}</button>
                                        <button className="btn btn-primary boton" onClick={handleLogs}>Logs</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <Modal className="custom-modal" show={showModal} onHide={() => {
                        setShowModal(false);
                        setPassword("");
                        setDeleteError("");
                    }}>
                        <Modal.Header className="custom-modal-header" closeButton={false}>
                            <Modal.Title className="custom-modal-title">{t('ConfirmarEliminacion')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                {t('ConfirmacionTextoParte1')}
                                <strong>{t('ConfirmacionTextoParte2')}</strong>
                                <u>{t('ConfirmacionTextoParte3')}</u>
                                <br /><br />
                                <strong>{t('ConfirmacionTextoParte4')}</strong>
                            </p>
                            <div className="form-group">
                                <label htmlFor="password">{t('IntroducirContrasena')}</label>
                                <input
                                    type="password"
                                    id="password"
                                    className="form-control"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            {deleteError && <div className="alert alert-danger mt-3">{deleteError}</div>}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {
                                setShowModal(false);
                                setPassword("");
                                setDeleteError("");
                            }}>
                                {t('Cancelar')}
                            </Button>
                            <Button variant="danger" onClick={confirmDelete}>
                                {t('EliminarCuentaBoton')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            ) : (
                <div className="text-center mt-5">
                    <h3>{t('Cargando')}</h3>
                </div>
            )}
        </div>
    );
};

export default Perfil;
