import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button} from 'react-bootstrap';
import axios from "axios";
//import PhoneInput from 'react-phone-input-2';
//import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import './Editar.css';

const EditarInformacion = () => {
    const { t } = useTranslation('editar');
    const navigate = useNavigate();
    const [perfil, setPerfil] = useState(null);
    /*const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');*/
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');
    const [nacimiento, setNacimiento] = useState('');
    /*const [telefono, setTelefono] = useState('');*/
    const [telegram, setTelegram] = useState('');
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const [enviado, setEnviado] = useState('');
    const token = localStorage.getItem('token');


    useEffect(() => {

        if (token) {
            axios.post(`https://api.elplanmadrid.org/datos`, { token: token })
                .then(response => {
                    if (response.status === 200) {
                        setPerfil(response.data);
                    } else {
                        localStorage.removeItem('token');
                        navigate("/login");
                    }
                })
                .catch(error => {
                    console.error('Datos no recibidos');
                    localStorage.removeItem('token');
                    navigate("/login");
                });
        } else {
            navigate("/login");
        }
    }, [token, navigate]);

    /*const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            timeZone: 'Europe/Madrid',  // Aseguramos la zona horaria de Madrid
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        };
        return date.toLocaleString(i18n.language === 'es' ? 'es-ES' : 'en-US', options);
    };*/


    const handleSubmit = async (event) => {
        event.preventDefault();
        setEnviado(true);

        // Validar la edad
        if (isAdult(nacimiento) < 18) {
            setError(t('error_underage')); // Mensaje de error para menores de edad
            return;
        }

        const data = {
            username,
            nacimiento,
            telegram,
            token
        };


        const keys = Object.keys(data);
        let i = 0;
        let cambioDatos = false;

        while (i < keys.length - 1 && !cambioDatos) {
            if (data[keys[i]] !== '') {
                cambioDatos = true;
            }
            i++;
        }

        let cambioPass = false;
        let data2 = null;

        if (oldPassword === '') {
            if (password !== '' || repeatedPassword !== '') {
                setError(t('error_no_old_password'));
                return;
            }
        } else {
            if (password !== '' || repeatedPassword !== '') {
                if (password === repeatedPassword) {
                    data2 = {
                        'old_password': oldPassword,
                        'new_password': password,
                        token
                    };
                    cambioPass = true;
                } else {
                    setError(t('error_password_mismatch'));
                    return;
                }
            }
        }

        if (cambioDatos) {
            try {
                const res = await axios.post("https://api.elplanmadrid.org/updateDatos", data);
                if (res.status === 200) {
                    if (!cambioPass) {
                        navigate("/perfil");
                    }
                }
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setError(t('error_username_taken'));
                } else {
                    setError(t('error_internal'));
                }
            }
        }

        if (cambioPass && data2) {
            try {
                const res = await axios.post("https://api.elplanmadrid.org/updatePassword", data2);
                if (res.status === 200) {
                    navigate("/perfil");
                }
            } catch (error) {
                if (error.response.status === 401) {
                    setError(t('error_wrong_old_password'));
                } else {
                    setError(t('error_internal'));
                }
            }
        }
    };


    const handleCancelar = () => {
        navigate("/perfil");
    }

    const isAdult = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);
        const age = today.getFullYear() - birth.getFullYear();
        const monthDifference = today.getMonth() - birth.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
            return age - 1;
        }
        return age;
    };

    /*const formatPhoneNumber = (phone) => {
        if (phone !== '-') {
            phone = '+' + phone;
            const phoneNumber = parsePhoneNumberFromString(phone);
            if (phoneNumber) {
                let code = '+' + phoneNumber.countryCallingCode;
                return `(${code}) ${phoneNumber.formatNational()}`;
            }
        }
        return phone;
    };*/

    return (
    <main className="main-content">
        <div className="edit-info-container">
            {perfil !== null && perfil !== undefined ? (
                <>
                    <h1 className="edit-title">{t('title')}</h1>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="form-class">
                        <div className="username-input-cont">
                            <Form.Label><strong>{t('username')}:</strong></Form.Label>
                            {/* <p><u>{t('current')}:</u> <em>{perfil.username}</em></p> */}
                            <Form.Control
                                type="text"
                                placeholder={t('new_username')}
                                onChange={(e) => setUsername(e.target.value)}
                                className="username-input"
                            />
                            <p class="username-warning">{t('username_warning')}</p>
                            </div>
                            <div className="birthday-input-cont">
                            <Form.Label><strong>{t('birth_date')}:</strong></Form.Label>
                            {/* <p><u>{t('current')}:</u> <em>{formatDate2(perfil.nacimiento)}</em></p> */}
                            <Form.Control
                                type="date"
                                onChange={(e) => setNacimiento(e.target.value)}
                                className="birthday-input"
                            />
                            </div>
                            <div className="telegram-input-cont">
                            <Form.Label><strong>{t('telegram')}:</strong></Form.Label>
                            {/* <p><u>{t('current')}:</u> <em>{perfil.telegram}</em></p> */}
                            <Form.Control
                                type="text"
                                placeholder={t('new_telegram')}
                                onChange={(e) => setTelegram(e.target.value)}
                                className="telegram-input"
                            />
                            </div>
                        </Form.Group>

                        {/*<Form.Group className="form-class">
                            <Form.Label><strong>{t('name')}:</strong></Form.Label>
                            <p><u>{t('current')}:</u> <em>{perfil.nombre}</em></p>
                            <Form.Control
                                type="text"
                                placeholder={t('new_name')}
                                onChange={(e) => setNombre(e.target.value)}
                                className="placeholder-center small-input"
                            />
                        </Form.Group>*/}

                        {/*<Form.Group className="form-class">
                            <Form.Label><strong>{t('surname')}:</strong></Form.Label>
                            <p><u>{t('current')}:</u> <em>{perfil.apellidos}</em></p>
                            <Form.Control
                                type="text"
                                placeholder={t('new_surname')}
                                onChange={(e) => setApellidos(e.target.value)}
                                className="placeholder-center small-input"
                            />
                        </Form.Group>*/}

                        {/*<Form.Group className="form-class">
                            <Form.Label><strong>{t('phone')}:</strong></Form.Label>
                            <p><u>{t('current')}:</u> <em>{formatPhoneNumber(perfil.telefono)}</em></p>
                            <PhoneInput
                                country={'es'}
                                value={telefono}
                                onChange={(phone) => setTelefono(phone)}
                                inputProps={{
                                    name: 'phone',
                                }}
                                inputStyle={{ width: '100%' }}
                                containerClass="placeholder-center small-input"
                            />
                        </Form.Group>*/}
                        <h2 class="password-change">Cambio de contraseña</h2>
                        <p className="password-warning">{t('password_change_warning')}</p>

                        <Form.Group className="form-class">
                            <div class="current-pass-cont">
                            <Form.Label><strong>{t('current_password')}:</strong></Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={t('current_password')}
                                onChange={(e) => setOldPassword(e.target.value)}
                                className="current-pass"
                            />
                            </div>
                            <div class="new-pass-cont">
                            <Form.Label><strong>{t('new_password')}:</strong></Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={t('new_password')}
                                onChange={(e) => setPassword(e.target.value)}
                                className="new-pass"
                            />
                            </div>
                            <div class="repeat-pass-cont">
                            <Form.Label><strong>{t('repeat_new_password')}:</strong></Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={t('repeat_new_password')}
                                onChange={(e) => setRepeatedPassword(e.target.value)}
                                className="repeat-new-pass"
                            />
                            </div>
                        </Form.Group>

                        {enviado && error && <p className="text-danger">{error}</p>}
                        <div className="button-cont">
                        <Button variant="primary" type="submit"className="confirm-btn">
                            {t('confirm_changes')}
                        </Button>
                        <Button onClick={handleCancelar} className="cancel-btn">
                            {t('cancel')}
                        </Button>
                        </div>
                    </Form>
                </>
            ) : (
                <div className="text-center mt-5">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">{t('loading')}</span>
                    </div>
                </div>
            )}
        </div>
    </main>
    );
}

export default EditarInformacion;
