import React from 'react';
import './Home.css';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";

const Home = () => {
    const { t } = useTranslation('home');

    return (
        <div className='home-container'>
            <div className="content-container">
                <div className="text-cont">
                    <h1 className='titulo'>{t('t1')}</h1>
                    <h3 className='subtitulo'>{t('t2')}</h3>
                    <div className='btn-container'>
                        <NavLink to="/registro" id="boton" className="registro">{t('b1')}</NavLink>
                        <NavLink to="/masinfo" id="boton" className="masinfo">{t('b2')}</NavLink>
                    </div>
                </div>
                <div className="img-cont">
                    <img src="/images/pillo-fb.png" className="img-pillo" alt="mascota de el plan, pillo" />
                </div>
            </div>
        </div>
        
    );
};

export default Home;
