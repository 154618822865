import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './tos.css';

const TOS = () => {
    const { t } = useTranslation('tos');

    return (
        <Container>
            <main className="main-cont">
                <div class="info-block">
                    <h1 className='tos-title'>{t('Titulo')}</h1>
                    <p className="intro-text">{t('Introduccion')}</p>
                </div>
                <div class="info-block">
                    <h2 className="secondary-title">{t('Seccion1Titulo')}</h2>
                    <p className="body-text">{t('Seccion1Contenido')}</p>
                </div>
                <div class="info-block">
                <h2 className="secondary-title">{t('Seccion2Titulo')}</h2>
                <p className="body-text">
                    {t('Seccion2Contenido')}
                    <Link to="/privacy">{t('PoliticaPrivacidad')}</Link>. {t('Seccion2ContenidoParte2')}
                </p>
                </div>
                <div className="info-block">
                    <h2 className="secondary-title">{t('Seccion3Titulo')}</h2>
                    <p className="body-text">{t('Seccion3Contenido')}</p>
                </div>
                <div className="info-block">
                    <h2 className="secondary-title">{t('Seccion4Titulo')}</h2>
                    <p className="body-text">{t('Seccion4Contenido')}</p>
                </div>
                <div className="info-block">
                    <h2 className="secondary-title">{t('Seccion5Titulo')}</h2>
                    <p className="body-text">{t('Seccion5Contenido')}</p>
                </div>
                <div className="info-block">
                    <h2 className="secondary-title">{t('Seccion6Titulo')}</h2>
                    <p className="body-text">{t('Seccion6Contenido')}</p>
                </div>
                <div className="info-block">
                    <h2 className="secondary-title">{t('Seccion7Titulo')}</h2>
                    <p className="body-text">{t('Seccion7Contenido')}</p>
                </div>
                <div className="info-block">
                    <h2 className="secondary-title">{t('Seccion8Titulo')}</h2>
                    <p className="body-text">{t('Seccion8Contenido')}</p>
                </div>
                <div className="info-block">
                <h2 className="secondary-title">{t('Seccion9Titulo')}</h2>
                <p className="body-text">{t('Seccion9Contenido')}</p>
                </div>
                <div className="info-block">
                <h2 className="secondary-title">{t('Seccion10Titulo')}</h2>
                <p className="body-text">{t('Seccion10Contenido')}</p>
                </div>
            </main>
        </Container>
    );
};

export default TOS;
