import React, { useState } from 'react';
import './recuperacion.css';
import { Modal, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Recuperacion = () => {
  const { t } = useTranslation('recuperacion');
  const [step, setStep] = useState(1); // 1: Request Code, 2: Verify Code
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleRequestCode = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://api.elplanmadrid.org/mailrecovery', { email });
      if (response.status === 200) {
        setStep(2);
        setError('');
      } else if (response.status === 204) {
        setStep(2);
        setError(t('error_codigo_ya_existente'));
      } else {
        setError(t('error_solicitar_codigo'));
      }
    } catch (error) {
      setError(t('error_red'));
    }
  };

  const handleVerifyCode = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://api.elplanmadrid.org/verify_code', { email, code });
      if (response.status === 200) {
        setShowModal(true);
        setError('');
      } else {
        setError(t('error_codigo_invalido'));
      }
    } catch (error) {
      setError(t('error_red'));
    }
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setError(t('error_contrasenas_no_coinciden'));
      return;
    }
    try {
      const response = await axios.post('https://api.elplanmadrid.org/recover_password', { email, password: newPassword });
      if (response.status === 200) {
        alert(t('contrasena_cambiada_exitosamente'));
        setShowModal(false);
        setStep(1);
        setEmail('');
        setCode('');
        setNewPassword('');
        setConfirmPassword('');
        setError('');
      } else {
        setError(t('error_cambiar_contrasena'));
      }
    } catch (error) {
      setError(t('error_red'));
    }
  };

  return (
    <main className='recovery-container'>
      <div className="recuperacion">
        <h1 className='recovery-title'>{t('recuperacion_contrasena')}</h1>
        {error && <Alert variant="danger">{error}</Alert>}
        {step === 1 && (
          <form onSubmit={handleRequestCode} class="recovery-form">
            <div className="e-mail-input">
              <label>{t('correo_electronico')}</label>
              <input
                type="email"
                className="form-control"
                placeholder={t('introduce_correo')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <Button variant="primary" type="submit" className="code-btn">
              {t('solicitar_codigo')}
            </Button>
          </form>
        )}
        {step === 2 && (
          <form onSubmit={handleVerifyCode}>
            <div className="form-group">
              <label>{t('codigo_recuperacion')}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t('introduce_codigo')}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <Button variant="primary" type="submit" className="mt-3">
              {t('confirmar_codigo')}
            </Button>
          </form>
        )}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('cambiar_contrasena')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleChangePassword}>
              <div className="form-group">
                <label>{t('nueva_contrasena')}</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder={t('introduce_nueva_contrasena')}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="form-group mt-3">
                <label>{t('confirmar_nueva_contrasena')}</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder={t('confirma_nueva_contrasena')}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <Button variant="primary" type="submit" className="mt-3">
                {t('cambiar_contrasena')}
              </Button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              {t('cerrar')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </main>
  );
};

export default Recuperacion;
