import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const TablaDesglosadaLogs = ({ logs, resetFilters, setResetFilters }) => {
  const { i18n } = useTranslation();  // Accede al idioma actual

  const [filterEjecutor, setFilterEjecutor] = useState('');
  const [filterReceptor, setFilterReceptor] = useState('');
  const [selectedAcciones, setSelectedAcciones] = useState([]);
  const [filterComentario, setFilterComentario] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,  // Formato de 24 horas
      timeZone: 'Europe/Madrid'  // Asegurar la zona horaria de Madrid
    };

    // Si el idioma es español, usamos 'es-ES'; de lo contrario, 'en-US'
    return new Date(dateString).toLocaleString(i18n.language === 'es' ? 'es-ES' : 'en-US', options);
  };


  // Resetear los filtros cuando resetFilters sea true
  useEffect(() => {
    if (resetFilters) {
      setFilterEjecutor('');
      setFilterReceptor('');
      setSelectedAcciones([]);
      setFilterComentario('');
      setFechaInicio('');
      setFechaFin('');
      setResetFilters(false); // Resetear el estado una vez los filtros se hayan limpiado
    }
  }, [resetFilters, setResetFilters]);

  if (!logs || logs.length === 0) {
    return <p>No hay logs disponibles.</p>;
  }

  // Obtener acciones únicas para el filtro
  const accionesUnicas = [...new Set(logs.map((log) => log.accion))];

  // Manejar selección de checkboxes para acciones
  const handleAccionChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedAcciones([...selectedAcciones, value]); // Agregar la acción seleccionada
    } else {
      setSelectedAcciones(selectedAcciones.filter((accion) => accion !== value)); // Remover si deseleccionada
    }
  };

  // Filtrar logs según los campos de texto, acciones seleccionadas y rango de fechas
  const filteredLogs = logs.filter((log) => {
    const matchesEjecutor = log.ejecutor.toLowerCase().includes(filterEjecutor.toLowerCase());
    const matchesReceptor = log.receptor.toLowerCase().includes(filterReceptor.toLowerCase());
    const matchesComentario = log.comentario.toLowerCase().includes(filterComentario.toLowerCase());
    const matchesAccion = selectedAcciones.length === 0 || selectedAcciones.includes(log.accion);

    const logFecha = new Date(log.fecha);
    const matchesFecha =
      (!fechaInicio || logFecha >= new Date(fechaInicio)) &&
      (!fechaFin || logFecha <= new Date(fechaFin));

    return matchesEjecutor && matchesReceptor && matchesAccion && matchesComentario && matchesFecha;
  });

  return (
    <div className="tabla-desglosada-logs mt-4">
      <h3>Desglose de Logs</h3>

      {/* Botón para limpiar filtros manualmente */}
      <button
        onClick={() => {
          setFilterEjecutor('');
          setFilterReceptor('');
          setSelectedAcciones([]);
          setFilterComentario('');
          setFechaInicio('');
          setFechaFin('');
        }}
        className="btn btn-secondary mb-3"
      >
        Limpiar Filtros
      </button>

      {/* Filtros */}
      <div className="row mb-3">
        {/* Filtro por Ejecutor */}
        <div className="col-md-3">
          <input
            type="text"
            className="form-control"
            placeholder="Filtrar por Ejecutor"
            value={filterEjecutor}
            onChange={(e) => setFilterEjecutor(e.target.value)}
          />
        </div>

        {/* Filtro por Receptor */}
        <div className="col-md-3">
          <input
            type="text"
            className="form-control"
            placeholder="Filtrar por Receptor"
            value={filterReceptor}
            onChange={(e) => setFilterReceptor(e.target.value)}
          />
        </div>

        {/* Filtro por Acciones */}
        <div className="col-md-3">
          <h5>Filtrar por Acción:</h5>
          {accionesUnicas.map((accion, index) => (
            <label key={index} className="form-check-label d-inline-block me-3">
              <input
                type="checkbox"
                value={accion}
                onChange={handleAccionChange}
                checked={selectedAcciones.includes(accion)}
              />
              {" "}{accion}
            </label>
          ))}
        </div>

        {/* Filtro por Comentario */}
        <div className="col-md-3">
          <input
            type="text"
            className="form-control"
            placeholder="Filtrar por Comentario"
            value={filterComentario}
            onChange={(e) => setFilterComentario(e.target.value)}
          />
        </div>
      </div>

      {/* Filtro por Rango de Fechas y Horas */}
      <div className="row mb-3">
        <div className="col-md-6">
          <label>Fecha y Hora de Inicio</label>
          <input
            type="datetime-local"
            className="form-control"
            value={fechaInicio}
            onChange={(e) => setFechaInicio(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <label>Fecha y Hora de Fin</label>
          <input
            type="datetime-local"
            className="form-control"
            value={fechaFin}
            onChange={(e) => setFechaFin(e.target.value)}
          />
        </div>
      </div>

      {/* Tabla de logs */}
      <div className='table-responsive'>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Ejecutor</th>
              <th>Receptor</th>
              <th>Acción</th>
              <th>Comentario</th>
              <th>Fecha</th>
            </tr>
          </thead>
          <tbody>
            {filteredLogs.map((log, index) => (
              <tr key={index}>
                <td>{log.ejecutor}</td>
                <td>{log.receptor}</td>
                <td>{log.accion}</td>
                <td>{log.comentario}</td>
                <td>{formatDate(log.fecha)}</td> {/* Muestra la fecha con formato según i18n */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TablaDesglosadaLogs;
