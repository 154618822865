import React, { useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';

const TablaDesglosada = ({ entradas, eventID }) => {
  const [filterText, setFilterText] = useState(''); // Filtro por usuario
  const [filterTelegram, setFilterTelegram] = useState(''); // Filtro por Telegram
  const [selectedApprovers, setSelectedApprovers] = useState([]); // Filtro para los approvers seleccionados

  if (!entradas || entradas.length === 0) {
    return <p></p>;
  }

  // Obtener una lista de approvers únicos
  const approversUnicos = [...new Set(entradas.map((entrada) => entrada.approver))];

  // Función para descargar el archivo CSV
  const descargarCSV = async () => {
    try {
      const response = await axios.post(
        'https://api.elplanmadrid.org/exportar_cuentas',
        {
          token: localStorage.getItem('token'),
          evento: eventID // Asegúrate de tener el evento seleccionado disponible
        },
        {
          responseType: 'blob', // Indica que esperas un archivo binario
        }
      );

      // Guardar el archivo CSV
      const blob = new Blob([response.data], { type: 'text/csv' });
      saveAs(blob, 'datos_exportados.csv');
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
    }
  };


  // Manejar selección de checkboxes para los approvers
  const handleApproverChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedApprovers([...selectedApprovers, value]); // Agregar el approver seleccionado
    } else {
      setSelectedApprovers(selectedApprovers.filter((approver) => approver !== value)); // Remover si deseleccionado
    }
  };

  // Filtrar entradas según los campos de texto y approvers seleccionados
  const filteredEntradas = entradas.filter((entrada) => {
    const matchesUser = entrada.usuario.toLowerCase().includes(filterText.toLowerCase());
    const matchesTelegram = entrada.telegram.toLowerCase().includes(filterTelegram.toLowerCase());
    const matchesApprover = selectedApprovers.length === 0 || selectedApprovers.includes(entrada.approver);

    return matchesUser && matchesTelegram && matchesApprover;
  });

  return (
    <div className="tabla-desglosada mt-4">
      <h3>Desglose de Entradas</h3>
      <button
        onClick={descargarCSV}  // Añadir evento onClick
        className="btn btn-primary ms-3"
      >
        Exportar
      </button>

      {/* Filtros */}
      <div className="row mb-3">
        {/* Filtro por Usuario */}
        <div className="col-md-4">

          <h5>Filtrar por Approver:</h5>
          {approversUnicos.map((approver, index) => (
            <label key={index} className="form-check-label d-inline-block me-3">
              <input
                type="checkbox"
                value={approver}
                onChange={handleApproverChange}
                checked={selectedApprovers.includes(approver)}
              />
              {" "}{approver}
            </label>
          ))}
        </div>

        {/* Filtro por Telegram */}
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            placeholder="Filtrar por Telegram"
            value={filterTelegram}
            onChange={(e) => setFilterTelegram(e.target.value)}
          />
        </div>

        {/* Checkboxes para filtrar por Approver */}
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            placeholder="Filtrar por Usuario"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
      </div>

      {/* Tabla de entradas */}
      <div className='table-responsive'>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Approver</th>
              <th>Telegram</th>
              <th>Usuario</th>
            </tr>
          </thead>
          <tbody>
            {filteredEntradas.map((entrada, index) => (
              <tr key={index}>
                <td>{entrada.approver}</td>
                <td>{entrada.telegram}</td>
                <td>{entrada.usuario}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TablaDesglosada;
