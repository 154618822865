import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Navbar.css';
import axios from 'axios';

const BarraNavegacion = () => {
  const [expanded, setExpanded] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const { t, i18n } = useTranslation('navbar');

  useEffect(() => {
    const checkLoginStatus = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.post(`https://api.elplanmadrid.org/username`, { token });
          if (response.status === 200) {
            setUsername(response.data.username);
            setLoggedIn(true);
          } else {
            setLoggedIn(false);
            localStorage.removeItem('token');
          }
        } catch (error) {
          setLoggedIn(false);
          localStorage.removeItem('token');
        }
      } else {
        setLoggedIn(false);
      }
    };

    checkLoginStatus();

    const handleLogin = () => {
      checkLoginStatus();
    };
    const handleLogout = () => {
      setLoggedIn(false);
      setUsername('');
    };

    window.addEventListener('login', handleLogin);
    window.addEventListener('logout', handleLogout);

    return () => {
      window.removeEventListener('login', handleLogin);
      window.removeEventListener('logout', handleLogout);
    };
  }, []);

  const handleToggle = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleNavClick = () => {
    setExpanded(false);
  };

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'es' : 'en';
    i18n.changeLanguage(newLang);
    localStorage.setItem('language', newLang);
  };

  return (
    <Navbar className="navbar-custom" expand="lg" fixed="top" expanded={expanded}>
      <Container fluid>
        <Navbar.Brand as={NavLink} to="/" className="navbar-brand-custom">
          <img
            src="/images/Nombre.png"
            alt="Logo"
            className="plan-logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}>
          <img
            src={expanded ? "/close.svg" : "/menu.svg"}
            alt={expanded ? "Close Icon" : "Menu Icon"}
            height="30"
            className={expanded ? "navbar-close-icon" : "navbar-open-icon"}
            style={{ filter: 'invert(1)' }}
          />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto" onClick={handleNavClick}>
            <Nav.Link className="nav-item" as={NavLink} to="/" exact="true" activeclassname="active">{t('Inicio')}</Nav.Link>
            <Nav.Link className="nav-item" as={NavLink} to="/galeria" activeclassname="active">{t('Galeria')}</Nav.Link>
            <Nav.Link className="nav-item" as={NavLink} to="/masinfo" activeclassname="active">{t('Masinfo')}</Nav.Link>
            {!loggedIn && (
              <Nav.Link className='login-btn' as={NavLink} to="/login" activeclassname="active">
                Login
              </Nav.Link>
            )}
            {loggedIn && (
              <Nav.Link as={NavLink} to="/perfil" activeclassname="active">
                {username}
              </Nav.Link>
            )}
            <button onClick={toggleLanguage} className=" btn-link">
              {i18n.language === 'en' ? (
                <img src="/images/Ingles.svg" alt="English Flag" height="20" className="flag-icon" />
              ) : (
                <img src="/images/Espanol.svg" alt="Spanish Flag" height="20" className="flag-icon" />
              )}
            </button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default BarraNavegacion;
