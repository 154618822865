import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
//import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import './Registro.css';

const RegistroFormulario = () => {
    const [formData, setFormData] = useState({
        /*nombre: '',
        apellidos: '',*/
        email: '',
        password: '',
        repeatedPassword: '',
        nacimiento: '',
        /*telefono: '',*/
        telegram: '',
        username: '',
        acceptTerms: false,
    });

    const [error, setError] = useState('');
    const [enviado, setEnviado] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation('registro');
    const token = localStorage.getItem('token');


    useEffect(() => {
        if (token) {
            navigate("/perfil");
        }
    }, [token, navigate]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    /*const handlePhoneChange = (phone) => {
        setFormData(prevState => ({
            ...prevState,
            telefono: phone
        }));
    };*/

    const isAdult = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);
        const age = today.getFullYear() - birth.getFullYear();
        const monthDifference = today.getMonth() - birth.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
            return age - 1;
        }
        return age;
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setEnviado(true);
        setError('');

        // Validar que el usuario es mayor de edad
        if (isAdult(formData.nacimiento) < 18) {
            setError(t('error_underage')); // Mostrar mensaje de error si es menor de edad
            return;
        }

        if (formData.password !== formData.repeatedPassword) {
            setError(t('ErrNoCoinciden'));
            return;
        }

        if (!formData.acceptTerms) {
            setError(t('DebeAceptarTerminos'));
            return;
        }

        try {
            const response = await axios.post('https://api.elplanmadrid.org/registro', formData);
            localStorage.setItem('token', response.data.token);
            navigate("/perfil");
        } catch (error) {
            console.error('Error al enviar los datos:', error);
            if (error.response) {
                switch (error.response.data.error) {
                    case "El correo electrónico ya está en uso":
                        setError(t('EmailEnUso'));
                        break;
                    case "El nombre de usuario ya está en uso":
                        setError(t('UsernameEnUso'));
                        break;
                    default:
                        setError(t('ErrorAlEnviar'));
                }
            } else {
                setError(t('ErrorAlEnviar'));
            }
        }
    };


    return (
        <Form onSubmit={handleSubmit}>
            <div className='pagina-registro'>
                <div className="register-container">
                    <div className='register-inputs'>
                    <h1 className="reg-title">Registro</h1>
                        <Form.Group className="reg-form-group" controlId="Username">
                            <div className='reg-input-cont'>
                            <Form.Label className="fw-bold reg-label">
                                {t('NombreUsuario')}:<sup style={{ color: 'red' }}>*</sup>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('NombreUsuario')}
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                required
                                aria-label={t('NombreUsuario')}
                            />
                            </div>

                            {/*<Form.Group className="mb-4" controlId="nombre">
                            <Form.Label className="fw-bold reg-label">
                                {t('Nombre')}:<sup style={{ color: 'red' }}>*</sup>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('Nombre')}
                                name="nombre"
                                value={formData.nombre}
                                onChange={handleChange}
                                required
                                aria-label={t('Nombre')}
                            />
                        </Form.Group>*/}

                            {/*<Form.Group className="mb-4" controlId="apellidos">
                            <Form.Label className="fw-bold reg-label">
                                {t('Apellidos')}:<sup style={{ color: 'red' }}>*</sup>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('Apellidos')}
                                name="apellidos"
                                value={formData.apellidos}
                                onChange={handleChange}
                                required
                                aria-label={t('Apellidos')}
                            />
                        </Form.Group>*/}
                        <div className='reg-input-cont'>
                            <Form.Label className="fw-bold reg-label">
                                {t('Email')}:<sup style={{ color: 'red' }}>*</sup>
                            </Form.Label>
                            <Form.Control
                                type="email"
                                placeholder={t('Email')}
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                aria-label={t('Email')}
                            />
                            </div>
                            <div className='reg-input-cont'>
                            <Form.Label className="fw-bold reg-label">
                                {t('Contraseña')}:<sup style={{ color: 'red' }}>*</sup>
                            </Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={t('Contraseña')}
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                                aria-label={t('Contraseña')}
                            />
                            </div>
                            <div className='reg-input-cont'>
                            <Form.Label className="fw-bold reg-label">
                                {t('RepetirContraseña')}:<sup style={{ color: 'red' }}>*</sup>
                            </Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={t('RepetirContraseña')}
                                name="repeatedPassword"
                                value={formData.repeatedPassword}
                                onChange={handleChange}
                                required
                                aria-label={t('RepetirContraseña')}
                            />
                            </div>
                            <div className='reg-input-cont'>
                            <Form.Label className="fw-bold reg-label">
                                {t('FechaNacimiento')}:<sup style={{ color: 'red' }}>*</sup>
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="nacimiento"
                                value={formData.nacimiento}
                                onChange={handleChange}
                                required
                                aria-label={t('FechaNacimiento')}
                            />
                            </div>
                            <div className='reg-input-cont'>
                            <Form.Label className="fw-bold reg-label">{t('UsuarioTelegram')}:<sup style={{ color: 'red' }}>*</sup></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('UsuarioTelegram')}
                                name="telegram"
                                value={formData.telegram}
                                onChange={handleChange}
                                required
                                aria-label={t('UsuarioTelegram')}
                            />
                            </div>
                            {/*<Form.Group className="mb-4" controlId="telefono">
                            <Form.Label className="fw-bold reg-label">{t('Teléfono')}:</Form.Label>
                            <PhoneInput
                                country={'es'}
                                value={formData.telefono}
                                onChange={handlePhoneChange}
                                inputProps={{
                                    name: 'phone',
                                }}
                            />
                        </Form.Group>*/}
                        <div className='reg-input-cont'>
                            <Form.Check
                            className='reg-check'
                                type="checkbox"
                                name="acceptTerms"
                                checked={formData.acceptTerms}
                                onChange={handleChange}
                                label={
                                    <span className='accept-terms'>
                                        {t('AceptarTerminos')}{' '}
                                        <a className="link-terms" href="/tos" target="_blank" rel="noopener noreferrer">{t('TerminosYCondiciones')}</a>{' '}
                                        {t('Y')}{' '}
                                        <a className="link-terms"  href="/privacy" target="_blank" rel="noopener noreferrer">{t('PoliticaPrivacidad')}</a>
                                    </span>
                                }
                                required
                            />
                            </div>

                            {enviado && error && <p className="text-danger">{error}</p>}

                            <Button className="reg-btn" variant="primary" type="submit">
                                {t('Registrarse')}
                            </Button>
                        </Form.Group>
                    </div>

                </div>
            </div>

        </Form>
    );
};

export default RegistroFormulario;
