import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { fetchEvents, checkUserRole } from '../../hooks/customhooks';
import { useTranslation } from 'react-i18next';
import TablaDesglosadaLogs from './TablaDesglosadaLogs';

const Logs = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [logsData, setLogsData] = useState(null);
  const [resetFilters, setResetFilters] = useState(false); // Estado para manejar el reseteo de los filtros
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const { t, i18n } = useTranslation('logs');

  const handleEventChange = (event) => {
    const selectedEventId = Number(event.target.value);
    setSelectedEvent(selectedEventId);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedEvent) {
      try {
        const response = await axios.post('https://api.elplanmadrid.org/log', { token, "evento": selectedEvent });
        setLogsData(response.data.logs);  // Guardar los logs en el estado
        setResetFilters(true); // Indicar que los filtros deben reiniciarse
      } catch (error) {
        console.error("Error al obtener los logs:", error);
      }
    }
  };

  useEffect(() => {
    if (!token) {
      navigate('/error');
      return;
    }

    const verifyRoleAndFetchEvents = async () => {
      const hasSufficientRole = await checkUserRole(3, token);

      if (!hasSufficientRole) {
        navigate('/error');
        return;
      }

      const eventsData = await fetchEvents(token, true);
      setEvents(eventsData);
    };

    verifyRoleAndFetchEvents();
  }, [token, navigate]); // Agregamos token y navigate como dependencias

  const formatDate = (dateString, eventId) => {
    if (eventId === -1) {
      return ''; // No devolver la fecha si el ID del evento es -1
    }
    const date = new Date(dateString);
    const options = {
      timeZone: 'Europe/Madrid',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    };
    return date.toLocaleString(i18n.language === 'es' ? 'es-ES' : 'en-US', options);
  };

  return (
    <div className="logs">
      <div className='container mt-5'>
        <h2 className="mt-5 mb-4">{t('Titulo')}</h2>
        <form onSubmit={handleSubmit}>
          <select
            className="form-control mt-5 mb-4"
            value={selectedEvent}
            onChange={handleEventChange}
          >
            <option value="">{t('SeleccionarEvento')}</option>
            {events.map(event => (
              <option key={event.id} value={event.id}>
                {event.nombre} {event.id !== -1 && `(${formatDate(event.fecha, event.id)})`}
              </option>
            ))}
          </select>
          <button
            type="submit"
            className="btn btn-primary mb-3"
            disabled={!selectedEvent} // El botón está deshabilitado si no hay un evento seleccionado
          >
            {t('Confirmar')}
          </button>
        </form>

        {/* Mostrar la tabla desglosada si hay logs */}
        {logsData && Array.isArray(logsData) && (
          <TablaDesglosadaLogs logs={logsData} resetFilters={resetFilters} setResetFilters={setResetFilters} />
        )}
      </div>
    </div>
  );
};

export default Logs;
