import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './entradas.css';
import { useNavigate } from 'react-router-dom';
import { checkUserRole, fetchEvents } from '../../hooks/customhooks';
import { useTranslation } from 'react-i18next';

const Entradas = () => {
  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [search, setSearch] = useState('');
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState('');
  const [attendees, setAttendees] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState({});
  const [loading, setLoading] = useState(false);
  const [eventCapacity, setEventCapacity] = useState(0);
  const [canUseGratuito, setCanUseGratuito] = useState(false);
  const [userIsRole3, setUserIsRole3] = useState(false); // Nuevo estado para verificar si el usuario es rol 3
  const [doubleClickConfirmed, setDoubleClickConfirmed] = useState({}); // Estado para confirmar el doble clic
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('entradas');

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate("/error");
      return;
    }

    const verifyUserAccess = async () => {
      try {
        setLoading(true);
        const hasSufficientRole = await checkUserRole(2, token);
        if (!hasSufficientRole) {
          navigate("/error");
          return;
        }

        await fetchUsers(token);
        setEvents(await fetchEvents(token, false));

        const hasRoleLevel3 = await checkUserRole(3, token);
        setCanUseGratuito(hasRoleLevel3);
        setUserIsRole3(hasRoleLevel3);
      } catch (error) {
        console.error('Error during access verification or data fetching:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUsers = async (token) => {
      try {
        const response = await axios.post('https://api.elplanmadrid.org/usuarios', { token });
        if (response.data && Array.isArray(response.data.usuarios)) {
          setUsers(response.data.usuarios);
        } else {
          console.error('Server response does not contain the expected list of users.');
          setUsers([]);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        setMessage(t('error_obtener_usuarios'));
        setMessageType('error');
      }
    };

    verifyUserAccess();
  }, [navigate, t]);

  const handleSearchChange = (event) => {
    let searchValue = event.target.value;
    if (searchValue.startsWith('@')) {
      searchValue = searchValue.substring(1);
    }
    setSearch(searchValue);
  };

  const handleEventChange = (event) => {
    const selectedEventId = Number(event.target.value);
    setSelectedEvent(selectedEventId);

    const selectedEvent = events.find(e => e.id === selectedEventId);
    if (selectedEvent) {
      setAttendees(selectedEvent.asistentes);
      setEventCapacity(selectedEvent.aforo);
    }
  };

  const handlePaymentMethodChange = (userId, method) => {
    setPaymentMethods((prevMethods) => ({
      ...prevMethods,
      [userId]: method,
    }));
  };

  const handleGrantEntry = async (userId) => {
    const token = localStorage.getItem('token');

    // Verificar si es necesario cambiar el botón a "Confirmar" para revocar
    if (userIsRole3 && attendees.includes(userId)) {
      if (!doubleClickConfirmed[userId]) {
        // Cambiar el estado para que el botón muestre "Confirmar"
        setDoubleClickConfirmed((prev) => ({
          ...prev,
          [userId]: true,
        }));
        return;
      } else {
        // Revocar entrada
        try {
          await axios.post('https://api.elplanmadrid.org/revocar_entrada', { usuario: userId, evento: selectedEvent, token });
          setMessage(`${t('entrada_revocada_correctamente')} ${users.find(u => u.id === userId).username}`);
          setMessageType('success');
          setAttendees((prevAttendees) => prevAttendees.filter(id => id !== userId));
        } catch (error) {
          console.error('Error revoking entry:', error);
          setMessage(t('error_revocar'));
          setMessageType('error');
        } finally {
          // Reiniciar el estado del botón para permitir futuros clics
          setDoubleClickConfirmed((prev) => ({
            ...prev,
            [userId]: false,
          }));
        }
        return;
      }
    }

    try {
      const response = await axios.post("https://api.elplanmadrid.org/otorgar_entrada", {
        usuario: userId,
        evento: selectedEvent,
        token,
        metodo: paymentMethods[userId]
      });

      if (response.status === 200) {
        const user = users.find(u => u.id === userId);
        setMessage(`${t('entrada_otorgada_correctamente')} ${user.username}`);
        setMessageType('success');
        setAttendees((prevAttendees) => [...prevAttendees, userId]);
      }
    } catch (error) {
      console.error('Error granting entry:', error);
      setMessage(t('error_otorgar'));
      setMessageType('error');
    }
  };



  const filteredUsers = users.filter(user =>
    (user.username && user.username.toLowerCase().includes(search.toLowerCase())) ||
    (user.telegram && user.telegram.toLowerCase().includes(search.toLowerCase()))
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
        timeZone: 'Europe/Madrid',  // Aseguramos la zona horaria de Madrid
        day: '2-digit',
        month: 'long',
        year: 'numeric'
    };
    return date.toLocaleString(i18n.language === 'es' ? 'es-ES' : 'en-US', options);
};


  const remainingEntries = eventCapacity - attendees.length;

  return (
    <div className="entradas">
      <div className="container mt-5">
        <h2 className="mb-4">{t('validar_entradas')}</h2>
        {loading && <p>{t('cargando')}</p>}
        {message && (
          <div className={`alert ${messageType === 'success' ? 'alert-success' : 'alert-danger'}`}>
            {message}
          </div>
        )}

        {selectedEvent && (
          <h5>
            <span className="highlighted-text">{t('entradas_restantes')}: {remainingEntries}</span>
          </h5>
        )}

        <select className="form-control mb-4" value={selectedEvent} onChange={handleEventChange}>
          <option value="">{t('seleccionar_evento')}</option>
          {events.map(event => (
            <option key={event.id} value={event.id}>
              {event.nombre} ({formatDate(event.fecha)})
            </option>
          ))}
        </select>

        <input
          type="text"
          className="form-control mb-4"
          placeholder={t('buscar_username')}
          value={search}
          onChange={handleSearchChange}
        />

        <table className="table table-striped">
          <thead>
            <tr>
              <th>{t('username')}</th>
              <th>{t('metodo_pago')}</th>
              <th>{t('acciones')}</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.username}</td>
                <td>
                  <select
                    className="form-control"
                    value={paymentMethods[user.id] || ''}
                    onChange={(e) => handlePaymentMethodChange(user.id, e.target.value)}
                    disabled={!selectedEvent}
                  >
                    <option value="">{t('seleccionar_metodo')}</option>
                    <option value="paypal">{t('paypal')}</option>
                    <option value="bizum">{t('bizum')}</option>
                    <option value="transferencia">{t('transferencia')}</option>
                    {canUseGratuito && <option value="gratuito">{t('gratuito')}</option>}
                  </select>
                </td>
                <td>
                  <button
                    className={`btn ${attendees.includes(user.id) ? 'btn-danger' : 'btn-success'}`}
                    onClick={() => handleGrantEntry(user.id)}
                    disabled={
                      !selectedEvent ||
                      (!attendees.includes(user.id) && (!paymentMethods[user.id] || remainingEntries <= 0))
                    }
                  >
                    {attendees.includes(user.id)
                      ? (doubleClickConfirmed[user.id] ? t('confirmar_revocar') : t('revocar_entrada'))
                      : t('otorgar_entrada')}
                  </button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Entradas;
