/*import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Masonry from 'react-masonry-css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Este archivo CSS es necesario para el componente de lightbox
import './Galeria.css';  // Archivo CSS personalizado para el diseño del mosaico

const Galeria = () => {
  const [imagenes, setImagenes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [loadedImages, setLoadedImages] = useState([]); // Estado para controlar las imágenes cargadas
  const eventId = 1; // Cambia este ID según el evento específico

  useEffect(() => {
    // Obtener el número de imágenes del evento
    axios.get(`https://api.elplanmadrid.org/imagenes/${eventId}`)
      .then(response => {
        const { evento, imagenes } = response.data;
        const imagePaths = [];

        // Generar las rutas de las imágenes en formato .jpg
        for (let i = 1; i <= imagenes; i++) {
          imagePaths.push(`https://api.elplanmadrid.org/imagenes/${evento}/${i}.jpg`);
        }

        setImagenes(imagePaths);
        setLoadedImages(new Array(imagePaths.length).fill(false)); // Inicializar el estado de imágenes cargadas
      })
      .catch(error => {
        console.error('Error al obtener las imágenes', error);
      });
  }, [eventId]);

  const handleImageLoad = (index) => {
    setLoadedImages(prev => {
      const newLoadedImages = [...prev];
      newLoadedImages[index] = true;
      return newLoadedImages;
    });
  };

  const handleError = (e, index) => {
    const failedSrc = e.target.src;
    if (failedSrc.endsWith('.jpg')) {
      // Intentar cargar la versión .png si la versión .jpg no se encuentra
      e.target.src = failedSrc.replace('.jpg', '.png');
    } else {
      // Remover la imagen de la lista si ambas versiones fallan
      setImagenes(prev => prev.filter((_, i) => i !== index));
      setLoadedImages(prev => prev.filter((_, i) => i !== index));
    }
  };

  const breakpointColumnsObj = {
    default: 4, // 4 columnas por defecto
    1100: 3, // 3 columnas en pantallas medianas
    700: 2, // 2 columnas en pantallas pequeñas
    500: 1 // 1 columna en pantallas muy pequeñas
  };

  return (
    <div className="galeria-container">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {imagenes.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`Imagen ${index + 1}`}
            className={`img-fluid galeria-imagen ${loadedImages[index] ? 'loaded' : 'loading'}`}
            onLoad={() => handleImageLoad(index)}
            onClick={() => {
              setPhotoIndex(index);
              setIsOpen(true);
            }}
            onError={(e) => handleError(e, index)}
          />
        ))}
      </Masonry>
      {isOpen && (
        <Lightbox
          mainSrc={imagenes[photoIndex]}
          nextSrc={imagenes[(photoIndex + 1) % imagenes.length]}
          prevSrc={imagenes[(photoIndex + imagenes.length - 1) % imagenes.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + imagenes.length - 1) % imagenes.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imagenes.length)
          }
          imageCaption={
            <div className="lightbox-thumbnails-container">
              <div className="lightbox-thumbnails">
                {imagenes.map((src, index) => (
                  <img
                    key={index}
                    src={src}
                    alt={`Thumbnail ${index + 1}`}
                    className={`thumbnail ${index === photoIndex ? 'highlighted' : ''}`}
                    onClick={() => setPhotoIndex(index)}
                  />
                ))}
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default Galeria;*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import './Galeria.css';

const Galeria = () => {
  const { t } = useTranslation('galeria');

  return (
    <div className="const-container">
      <h1 className="const-title">{t('en_construccion')}</h1>
      <img className="gif" src='https://64.media.tumblr.com/5202ae1aa3dc9a79e4c3b0dd910e3a35/f19d2181fc4fbb73-26/s540x810/33fefdc23a4caf492b6a1a4e9770e8a587cb89b3.gifv' alt={t('imagen_alt')} />
    </div>
  );
}

export default Galeria;
